// overrides log function, muting it unless window.__DEBUG_MODE evaluates to a truthy value
const logOverrideFunction = (function (logFunction) {
  return function (message: string, ...optionalParams: any) {
    if (window.__DEBUG_MODE) {
      logFunction(message, ...optionalParams);
    }
  };
} (console.log));

// Then redefine the old console
if (import.meta.env.MODE !== "development")
  console.log = logOverrideFunction;